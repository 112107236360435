import {
  CloudWatchLogsClient,
  CreateLogStreamCommand,
  DescribeLogStreamsCommand,
  PutLogEventsCommand
} from "@aws-sdk/client-cloudwatch-logs";

const client = new CloudWatchLogsClient({
  region: process.env.GATSBY_AWS_REGION,
  credentials: {
    accessKeyId: process.env.GATSBY_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.GATSBY_AWS_SECRET_ACCESS_KEY,
  }
});

const logGroupName = process.env.GATSBY_AWS_LOG_GROUP_NAME;

const getLogStreamName = () => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(Math.floor(now.getUTCMinutes() / 15) * 15).padStart(2, '0'); // Round to nearest 15 minutes
  return `${year}/${month}/${day}/stream-${hours}-${minutes}`;
};

const createLogStream = async () => {
  const logStreamName = getLogStreamName();
  try {
    const describeLogStreamsCommand = new DescribeLogStreamsCommand({
      logGroupName,
      logStreamNamePrefix: logStreamName
    });
    const data = await client.send(describeLogStreamsCommand);

    const logStreamExists = data.logStreams.some(stream => stream.logStreamName === logStreamName);

    if (!logStreamExists) {
      const createLogStreamCommand = new CreateLogStreamCommand({
        logGroupName,
        logStreamName
      });
      await client.send(createLogStreamCommand);
    } else {
      //console.log(`Log stream already exists: ${logStreamName}`);
    }
  } catch (err) {
    console.error('Error creating or checking log stream:', err);
  }
};

const getSequenceToken = async () => {
  const logStreamName = getLogStreamName();
  const command = new DescribeLogStreamsCommand({
    logGroupName,
    logStreamNamePrefix: logStreamName
  });
  const data = await client.send(command);
  return data.logStreams[0].uploadSequenceToken;
};

const putLogEvents = async (logEvents) => {
  const logStreamName = getLogStreamName();
  await createLogStream();

  const sendLogs = async (retries = 0) => {
    try {
      const sequenceToken = await getSequenceToken();
      const command = new PutLogEventsCommand({
        logGroupName,
        logStreamName,
        logEvents,
        sequenceToken
      });
      await client.send(command);
    } catch (err) {
      if (err.name === 'ThrottlingException' && retries < 5) {
        const delay = Math.pow(2, retries) * 100; // Exponential backoff
        console.warn(`ThrottlingException encountered. Retrying in ${delay}ms...`);
        setTimeout(() => sendLogs(retries + 1), delay);
      } else {
        console.error('Error putting log events:', err);
      }
    }
  };

  await sendLogs();
};

export { createLogStream, putLogEvents, getLogStreamName };