module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-rudderstack/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"2WDkE3SPYosU7Crnzg7E38XZv5P","trackPage":true,"trackPageDelay":50,"dataPlaneUrl":"https://decorazzirtofr.dataplane.rudderstack.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":false,"loadType":"default","useLegacySDK":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
